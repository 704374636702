import {
    IcApkDownload,
    IcApkDownloadShort,
    IcAppstoreQr,
    IcFirstBannerIcon,
    IcFirstBannerIcon2,
    IcFirstBannerLight,
    IcFirstBannerLight2,
    IcFirstBannerLogo,
    IcFirstBannerStars2,
    IcFirstBg,
    IcFirstBubble1,
    IcFirstBubble2,
    IcFirstBubble2Mobile,
    IcFirstLogo,
    IcFirstMobileStars,
    IcFirstStars,
    IcGoogleQr,
    IcSecondBannerLogo,
    IcSoonBtn,
    IcSwiperLeft,
    IcSwiperRight,
} from "../../constants/icons";
import "./firstpage.css";
import PhoneSrc from "../../assets/images/first-phone.png";
import ApkDownloadSrc from "../../assets/images/ic-apk-download.png";
import ApkDownloaShortdSrc from "../../assets/images/ic-apk-download-short.png";
import IconAndroid from "../../assets/images/icon/ic-android.png";
import PhoneBannerSrc from "../../assets/images/first-banner-img.png";
import BannerBgSrc from "../../assets/images/first-banner-second.png";
import PhoneBannerMobileSrc from "../../assets/images/first-mobile-phone.png";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination } from "swiper/modules";
// Import Swiper styles
import "swiper/css";
import { Mobile, PC } from "../../hooks/useMedia";
import { useState } from "react";
import QrInfo from "../../components/QrInfo";
import { downloadApk, commingSoon } from "../../utils/common";
import appGuide from "../../assets/videos/29mall_Install_Guide.mp4";

const First = ({ onDownloadApk }) => {
    return (
        <div className="first-page">
            <PC>
                <IcFirstBg className="first-page-bg" />
            </PC>
            <Mobile>
                <IcFirstBannerLight className="first-page-bg-light" />
                <IcFirstBannerIcon className="first-page-bg-icon" />
            </Mobile>
            <div className="first-left">
                <p className="first-title">스마트한 쇼핑 동반자</p>
                <IcFirstLogo className="first-logo" />
                <PC>
                    <QrInfo />
                    {/* <div className="download-button-wrapper">
                        <button className="download-button first" type="button" onClick={() => onDownloadApk("apk")}>
                            <img src={IconAndroid} className="ic-android" />
                            Download APK Android
                        </button>
                        <button className="download-button first" type="button" onClick={() => onDownloadApk("guide")}>
                            APK 이용 가이드
                        </button>
                    </div> */}
                    {/* <IcApkDownload /> */}
                </PC>
                <Mobile>
                    {/* <IcSoonBtn className="first-soon" /> */}
<QrInfo />
                </Mobile>
            </div>
            <div className="first-right">
                <PC>
                    <IcFirstStars className="first-page-bg-stars" />
                </PC>
                <Mobile>
                    <IcFirstMobileStars className="first-page-bg-stars" />
                </Mobile>
                <img src={PhoneSrc} />
            </div>
        </div>
    );
};

const Second = ({ onDownloadApk }) => {
    return (
        <div className="first-page-second">
            <PC>
                <img src={BannerBgSrc} className="first-page-second-bg" />
                <img src={PhoneBannerSrc} className="first-page-second-phone" />
                {/* <IcFirstBubble2 className="first-page-second-bubble right" /> */}
                <div className="first-page-second-title">
                    <IcSecondBannerLogo className="logo" />
                    <div className="first-banner-download">
                        <QrInfo color="black" />
                        {/* <div className="download-button-wrapper small">
                            <button className="download-button second" type="button" onClick={() => onDownloadApk("apk")}>
                                <img src={IconAndroid} className="ic-android" />
                                Download APK Android
                            </button>
                            <button className="download-button second" type="button" onClick={() => onDownloadApk("guide")}>
                                APK 이용 가이드
                            </button>
                        </div> */}
                    </div>
                </div>
                <IcFirstBubble1 className="first-page-second-bubble" />
            </PC>
            <Mobile>
                <IcFirstBannerLogo className="first-page-banner-logo" />
                <QrInfo color="black" />
                <IcFirstBannerLight2 className="first-page-bg-light2" />
                <IcFirstBannerStars2 className="first-page-bg-stars2" />
                <img src={PhoneBannerMobileSrc} className="first-page-second-phone" />
                <IcFirstBubble2Mobile className="first-page-second-bubble right" />
            </Mobile>
        </div>
    );
};
const FirstPage = () => {
    const [swiper, setSwiper] = useState(); // -> 슬라이드용

    const onClickButton = (type) => {
        if (type === "apk") {
            downloadApk();
            return;
        }
        if (type === "guide") {
            window.open(appGuide);
            return;
        }
        commingSoon();
    };

    // 2. 슬라이드 이벤트핸들러
    const handlePrev = () => {
        swiper?.slidePrev();
    };
    const handleNext = () => {
        swiper?.slideNext();
    };

    return (
        <>
            <Swiper
                onSwiper={(e) => {
                    setSwiper(e);
                }}
                pagination={{
                    type: "fraction",
                }}
                modules={[
                    // Autoplay,
                    Pagination,
                ]}
                loop={true}
                // autoplay={{ delay: 5000, disableOnInteraction: false }}
            >
                <SwiperSlide>
                    <First onDownloadApk={onClickButton} />
                </SwiperSlide>
                <SwiperSlide>
                    <Second onDownloadApk={onClickButton} />
                </SwiperSlide>
                <div className="first-swiper-btn">
                    <IcSwiperLeft className="right" onClick={handlePrev} />
                    <IcSwiperRight onClick={handleNext} />
                </div>
            </Swiper>
        </>
    );
};

export default FirstPage;
