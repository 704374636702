import "./assets/css/global.css";
import "./App.css";

import ScrollToTop from "./hooks/ScrollToTop";
import Main from "./routers/Main";
import Agreement from "./routers/Agreement";
import Shop from './routers/Shop';
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import ShopMore from './routers/ShopDetail';
import ShopFail from './pages/ShopFail/ShopFail';
import ShopSuccess from './pages/ShopSuccess/ShopSuccess';

function App() {
    return (
        <>
            <BrowserRouter>
                <ScrollToTop />
                <Routes>
                    <Route path="/" element={<Main />} />
                    <Route path="/agreement/:type" element={<Agreement />} />
                    <Route path="/store" element={<Shop />} />
                    <Route path="/store/:id" element={<ShopMore />} />
                    <Route path="/store/success" element={<ShopSuccess />} />
                    <Route path="/store/fail" element={<ShopFail />} />
                    <Route path="*" element={<Navigate to="/" />} />
                </Routes>
            </BrowserRouter>
        </>
    );
}
export default App;
