import {
    IcAppstoreBtn,
    IcAppstoreBtnBlack,
    IcAppstoreQr,
    IcAppstoreQrBlack,
    IcAppstoreQrBottom,
    IcGoogleBtn,
    IcGoogleBtnBlack,
    IcGooglePlay,
    IcGoogleQr,
    IcGoogleQrBlack,
    IcGoogleQrBottom,
} from "../constants/icons";
import { Mobile, PC } from "../hooks/useMedia";
import { commingSoon, onClickAppDownLink } from "../utils/common";
import QrSrc from "../assets/images/ic-qr.png";
// import IcAppstoreQr from "../assets/images/icon/ic-appstore-qr.png";
// import IcGoogleQr from "../assets/images/icon/ic-google-qr.png";

const QrInfo = ({ color = "white", size = "" }) => {
    return (
        <div className="qr-info">
            <PC>
                {color == "white" ? (
                    size == "big" ? (
                        <>
                            <IcGoogleQrBottom />
                            <IcAppstoreQrBottom
                            // style={{ marginLeft: "-12px" }}
                            />
                        </>
                    ) : (
                        <>
                            {/* <div className="qr-info-body">
                            <IcGooglePlay />
                            <img src={QrSrc} />
                        </div> */}
                            <IcGoogleQr />
                            <IcAppstoreQr />
                            {/* <img src={IcAppstoreQr} />
                            <img src={IcGoogleQr} /> */}
                        </>
                    )
                ) : (
                    <>
                        <IcGoogleQrBlack />
                        <IcAppstoreQrBlack />
                    </>
                )}
            </PC>
            <Mobile>
                <div className="download-btn">
                    {color === "white" ? (
                        <>
                            <IcGoogleBtn onClick={() => onClickAppDownLink("googlestore")} />
                            <IcAppstoreBtn onClick={() => onClickAppDownLink("appstore")} />
                        </>
                    ) : (
                        <div className="link-btn-mobile">
                            <IcGoogleBtnBlack onClick={() => onClickAppDownLink("googlestore")} />
                            <IcAppstoreBtnBlack onClick={() => onClickAppDownLink("appstore")} />
                        </div>
                    )}
                </div>
            </Mobile>
        </div>
    );
};

export default QrInfo;
