import { SubTitle, Title } from "../../components/Text";
import PhoneSrc from "../../assets/images/third-phone.png";
import "./thirdpage.css";
import { Circle } from "../../components/Circle";
import { IcThirdMobileBg, IcThirdTitle, IcThridBg } from "../../constants/icons";
import { Mobile, PC } from "../../hooks/useMedia";

const CIRCLE = ["vegi", "fish", "bread"];

const ThirdPage = () => {
    return (
        <div className="third-page">
            <div className="third-left">
                <PC>
                    <IcThridBg className="third-bg" />
                </PC>
                <Mobile>
                    <IcThirdMobileBg className="third-bg" />
                </Mobile>
                <div>
                    <img src={PhoneSrc} />
                </div>
            </div>
            <div className="third-right">
                <IcThirdTitle className="third-title" />
                {/* <Title color="pink">ACTS29 MALL</Title> */}
                <SubTitle>
                    신선하고, 맛있는 <span>고품질의 식품</span>
                    {"을 찾는\n 고객님을 위한 Mall"}
                </SubTitle>
                <div className="circle-wrapper">
                    {CIRCLE.map((li) => (
                        <Circle key={li} type={li} />
                    ))}
                </div>
            </div>
        </div>
    );
};

export default ThirdPage;
