import { IcSquare1, IcSquare2, IcSquare3, IcSquare4, IcVegi } from "../constants/icons";

const ICON = [
    { icon: <IcSquare1 />, title: "원스톱 쇼핑 경험" },
    { icon: <IcSquare2 />, title: "최고의 품질" },
    { icon: <IcSquare3 />, title: "안전한 결제" },
    { icon: <IcSquare4 />, title: "맞춤형 추천" },
];

export const Square = ({ num }) => {
    return (
        <div className="square">
            <div className="sqaure-tag">{num + 1}</div>
            <div className="square-icon">{ICON[num].icon}</div>
            <p className="square-title">{ICON[num].title}</p>
        </div>
    );
};
