import React from "react";
import { useMediaQuery } from "react-responsive";

export const Mobile = ({ children }) => {
    const isMobile = useMediaQuery({
        query: "(max-width:1439px)",
    });

    return <>{isMobile && children}</>;
};

export const PC = ({ children }) => {
    const isPc = useMediaQuery({
        query: "(min-width:1439px)",
    });

    return <>{isPc && children}</>;
};

export const useCustomMediaQuery = () => {
    const isMobile = useMediaQuery({
        query: "(max-width:1439px)",
    });
    const isPc = useMediaQuery({
        query: "(min-width:1439px)",
    });

    return { isMobile, isPc };
};
