import { IcFooterLogo } from "../../constants/icons";
import { Mobile, PC } from "../../hooks/useMedia";
import privacy_policy from "../../assets/download/Acts29_Mall_privacyPolicy.pdf";
import term_of_use from "../../assets/download/Acts29_Mall_termOfUse.pdf";
import "./layout.css";
import { useNavigate } from "react-router-dom";

const Footer = () => {
    const navigate = useNavigate();

    return (
        <div className="footer">
            <div className="footer-wrap">
                <p className="footer-company-title">주식회사 액츠</p>

                <div className="footer-email">
                    <p>
                        <span className="footer-title">대표이사</span>손권철
                    </p>
                    <p>
                        <span className="footer-title">사업자등록번호</span>332-87-03278
                    </p>
                    <p>
                        <span className="footer-title">주소</span>경기도 부천시 원미구 부흥로315번길 62, 2층 203, 204호(중동)
                    </p>
                    <p className="email">
                        <span className="footer-title">이메일</span>acts29mall@naver.com
                    </p>
                    <p>
                        <span className="footer-title">통신판매업신고번호</span>2024-부천원미-2030
                    </p>
                </div>
            <div className="footer-policy">
                {/* <a href={privacy_policy} target="_blank"> */}
                <p
                    className="policy"
                    onClick={() => {
                        navigate("/agreement/privacy");
                    }}
                >
                    개인정보처리방침
                </p>
                {/* </a> */}
                <div className="footer-policy-border" />
                {/* <a href={term_of_use} target="_blank"> */}
                <p
                    className="policy"
                    onClick={() => {
                        navigate("/agreement/terms");
                    }}
                >
                    서비스이용약관
                </p>
            </div>
            <div className="footer-info">
                <IcFooterLogo />
            </div>
            
            </div>
        </div>
    );
};

export default Footer;
