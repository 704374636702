import { IcBread, IcDigital, IcFashion, IcFish, IcFurniture, IcVegi } from "../constants/icons";

const ICON = {
    fashion: { icon: <IcFashion />, title: "패션의류/잡화" },
    furniture: { icon: <IcFurniture />, title: "가구/인테리어" },
    digital: { icon: <IcDigital />, title: "가전/디지털" },
    vegi: { icon: <IcVegi />, title: "농산물" },
    fish: { icon: <IcFish />, title: "수산물" },
    bread: { icon: <IcBread />, title: "가공산업" },
};

export const Circle = ({ type }) => {
    return (
        <div className="circle">
            <div className="circle-blur">{ICON[type].icon}</div>
            <p className="circle-title">{ICON[type].title}</p>
        </div>
    );
};
