import "./text.css";

export const HeaderTitle = ({ children, className }) => {
    return <p className={`header-title ${className}`}>{children}</p>;
};

export const Title = ({ children, className, color }) => {
    return <p className={`title ${className} ${color}`}>{children}</p>;
};

export const SubTitle = ({ children, className }) => {
    return <p className={`sub-title ${className}`}>{children}</p>;
};
