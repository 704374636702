import { useEffect } from "react";
import { useNavigate, useParams, Navigate } from "react-router-dom";

import Terms from "../components/agreement/Terms/Terms";
import Privacy from "../components/agreement/Privacy/Privacy";

const Agreement = () => {
    const { type } = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        if (!type) {
            navigate("/");
        }
    }, []);

    return (
        <div className="agreement-wrapper">
            {type === "terms" ? <Terms /> : type === "privacy" ? <Privacy /> : <Navigate replace to="/"></Navigate>}
        </div>
    );
};

export default Agreement;
