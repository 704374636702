import { Circle } from "../../components/Circle";
import { HeaderTitle, SubTitle, Title } from "../../components/Text";
import PhoneSrc from "../../assets/images/second-phone.png";
import "./secondpage.css";
import { IcSecondBg, IcSecondTitle } from "../../constants/icons";
import { Mobile } from "../../hooks/useMedia";

const CIRCLE = ["fashion", "furniture", "digital"];

const SecondPage = () => {
    return (
        <div className="second-page">
            <HeaderTitle className="second-header-title">
                버튼 하나로 즐기는 <Mobile>{"\n"}</Mobile> <span>두개의 쇼핑몰</span>
            </HeaderTitle>
            <div className="second-body">
                <div className="second-left">
                    <IcSecondTitle className="second-title" />
                    {/* <Title>ACTS29 PREMIUM</Title> */}
                    <SubTitle>
                        <span>럭셔리한 라이프스타일</span>
                        {"을 추구하는\n 고객님을 위한 Premium"}
                    </SubTitle>
                    <div className="circle-wrapper">
                        {CIRCLE.map((li) => (
                            <Circle key={li} type={li} />
                        ))}
                    </div>
                </div>
                <div className="second-right">
                    <img src={PhoneSrc} />
                    <IcSecondBg className="second-bg" />
                </div>
            </div>
        </div>
    );
};

export default SecondPage;
