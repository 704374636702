import QrInfo from "../../components/QrInfo";
import { IcQrMobileBg, IcQrPageBg } from "../../constants/icons";
import { Mobile, PC } from "../../hooks/useMedia";
import ApkDownloadLongSrc from "../../assets/images/ic-apk-download-long.png";
import ApkDownloadMobileSrc from "../../assets/images/ic-apk-download-mobile.png";
import IconAndroid from "../../assets/images/icon/ic-android.png";
import { commingSoon, downloadApk } from "../../utils/common";
import appGuide from "../../assets/videos/29mall_Install_Guide.mp4";
import "./qrpage.css";

const QrPage = () => {
    const onClickButton = (type) => {
        if (type === "apk") {
            downloadApk();
            return;
        }
        if (type === "guide") {
            window.open(appGuide);
            return;
        }
        commingSoon();
    };
    return (
        <div className="qr-page">
            <PC>
                <IcQrPageBg className="qr-page-bg" />
            </PC>
            <Mobile>
                <IcQrMobileBg className="qr-page-bg" />
            </Mobile>
            <div className="qr-page-body">
                <p className="qr-page-title">지금 시작하세요!</p>
                <QrInfo size="big" />
                <p className="qr-page-contents">기존 apk 설치 버전은 삭제 후 이용하세요!</p>
            </div>
        </div>
    );
};

export default QrPage;
