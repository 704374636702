import Header from "../pages/Layout/Header";
import FirstPage from "../pages/FirstPage/FirstPage";
import SecondPage from "../pages/SecondPage/SecondPage";
import ThirdPage from "../pages/ThirdPage/ThirdPage";
import FourthPage from "../pages/FourthPage/FourthPage";
import FifthPage from "../pages/FifthPage/FifthPage";
import LastPage from "../pages/LastPage/LastPage";
import Footer from "../pages/Layout/Footer";
import QrPage from "../pages/QrPage/QrPage";

const Main = () => {
    return (
        <>
            <Header />
            <FirstPage />
            <FifthPage />
            <SecondPage />
            <ThirdPage />
            <FourthPage />
            <LastPage />
            <QrPage />
            <Footer />
        </>
    );
};

export default Main;
