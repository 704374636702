import "../agreement.css";

const Terms = () => {
    return (
        <div className="terms-wrapper">
            <div title="Page 1">
                <span>저희 "주식회사 액츠"는 공정거래위원회의 표준약관을 준수 합니다</span>
                <br /> <br />
                <p>
                    <span className="agreement-subtitle"> 제1조(목적)</span> <br />
                    <span>
                        이 약관은 주식회사 액츠(이하 "본사"라고 한다)가 운영하는 사이버 몰인 "29Mall"(이하 "몰"이라 한다)에서 제공하는 쇼핑몰 서비스(이하
                        "서비스"라 한다)를 이용함에 있어 사이버 몰과 이용자의 권리&#xb7;의무 및 책임사항을 규정함을 목적으로 합니다. <br /> <br />※ PC
                        통신, 무선 등을 이용하는 전자상거래에 대해서도 그 성질에 반하지 않는 한 이 약관을 준용합니다.
                    </span>
                </p>
                <span>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</span>
                <p>
                    <span className="agreement-subtitle">제2조(정의)</span> <br />
                    <span>
                        ①<br /> "몰"이란 "29mall"이 재화 또는 용역(이하 "재화 등"이라 함)을 이용자에게 제공하기 위하여 컴퓨터 등 정보통신 설비를
                        이용하여 재화 등을 거래할 수 있도록 설정한 가상의 영업장을 말하며, 아울러 사이버몰을 운영하는 사업자의 의미로도 사용합니다.
                        <br /> ② <br />
                        "이용자"란 "몰"에 접속하여 이 약관에 따라 "몰"이 제공하는 서비스를 받는 일반회원 및 비회원을 말합니다.
                        <br /> 1) 일반회원(구매자) : 회사에서 제공하는 구매서비스 및 판매서비스를 이용할 수 있는 개인이나 법인 <br />
                        2) 비회원(구매자) : 회사에서 제공하는 구매서비스를 이용할 수 있는 개인이나 법인
                        <br /> ③ <br /> '일반회원'이라 함은 "몰"에 개인 정보를 제공하여 회원 등록을 한 자로서, "몰"의 정보를 지속적으로 제공받으며,
                        "몰"이 제공하는 서비스를 계속적으로 이용할 수 있는 자를 말합니다.
                    </span>
                </p>
                <span>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</span>
                <p>
                    <span className="agreement-subtitle">제3조 (약관 등의 명시와 설명 및 개정)</span> <br />
                    <span>
                        ① <br />
                        "몰"은 이 약관의 내용과 상호 및 대표자 성명, 영업소 소재지 주소(소비자의 불만을 처리할 수 있는 곳의 주소를 포함), 전화번호,
                        모사전송 번호, 전자우편 주소, 사업자등록번호, 통신판매업 신고번호, 개인 정보관리 책임자 등을 이용자가 쉽게 알 수 있도록
                        사이버몰의 초기 서비스 화면(전면)에 게시합니다. <br />
                        다만, 약관의 내용은 이용자가 연결 화면을 통하여 볼 수 있도록 할 수 있습니다.
                        <br /> ② <br />
                        "몰은 이용자가 약관에 동의하기에 앞서 약관에 정하여져 있는 내용 중 청약철회, 배송책임, 환불 조건 등과 같은 중요한 내용을
                        이용자가 이해할 수 있도록 별도의 연결 화면 또는 팝업 화면 등을 제공하여 이용자의 확인을 구하여야 합니다.
                        <br /> ③<br /> "몰"은 전자상거래 등에서의 소비자보호에 관한 법률, 약관의 규제에 관한 법률, 전자거래기본법, 전자서명법,
                        정보통신망 이용 촉진 등에 관한 법률, 소비자보호법 등 관련 법을 위배하지 않는 범위에서 이 약관을 개정할 수 있습니다. <br /> ④
                        <br />
                        "몰"이 약관을 개정할 경우에는 적용일자 및 개정 사유를 명시하여 현행 약관과 함께 몰의 초기 화면에 그 적용일자 7일 이전부터
                        적용일자 전일까지 공지합니다.
                        <br /> 다만, 이용자에게 불리하게 약관 내용을 변경하는 경우에는 최소한 30일 이상의 사전 유예기간을 두고 공지합니다. 이 경우
                        "몰"은 개정 전 내용과 개정 후 내용을 명확하게 비교하여 이용자가 알기 쉽도록 표시합니다.
                        <br /> ⑤ <br /> "몰"이 약관을 개정할 경우에는 그 개정 약관은 그 적용일자 이후에 체결되는 계약에만 적용되고 그 이전에
                        이미체결된 계약에 대해서는개정 전의 약관조항이 그대로 적용됩니다.
                        <br /> 다만, 이미 계약을 체결한 이용자가 개정 약관 조항의 적용을 받기를 원하는 뜻을 제3항에 의한 개정 약관의 공지기간 내에
                        '몰"에 송신하여 "몰"의 동의를 받은 경우에는 개정 약관 조항 이 적용됩니다. <br /> ⑥<br /> 이 약관에서 정하지 아니한 사항과 이
                        약관의 해석에 관하여는 전자상거래 등에서의 소비자보호에 관한 법률, 약관의 규제 등에 관한 법률, 공정거래위원회가 정하는
                        전자상거래 등에서의 소비자보호 지침 및 관계법령 또는 상관례에 따릅니다.
                    </span>
                </p>
                <span>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</span>
                <p>
                    <span className="agreement-subtitle">제4조(서비스의 제공 및 변경)</span> <br />
                    <span>
                        ① <br />
                        "몰"은 다음과 같은 업무를 수행합니다.
                        <br /> 1. <br />
                        판매관련 업무지원서비스 <br />
                        2.
                        <br /> 구매관련 업무지원서비스
                        <br /> 3.
                        <br /> 상품 정보검색 서비스
                        <br /> 4.
                        <br /> 기타 전자상거래 관련 서비스 기타 <br />
                        "몰"이 정하는 업무 <br />①<br /> "몰"은 재화 또는 용역의 품절 또는 기술적 사양의 변경 등의 경우에는 장차 체결되는 계약에 의해
                        제공할 재화 또는 용역의 내용을 변경할 수 있습니다. 이 경우에는 변경된 재화 또는 용역의 내용 및 제공 일자를 명시하여 현재의
                        재화 또는 용역의 내용을 게시한 곳에 즉시 공지합니다.
                        <br /> ②<br /> “본사”가 제공하는 전항의 서비스는 회원이 재화 등을 거래할 수 있도록 사이버몰의 이용을 허락하거나, 통신판매 및
                        전자상거래 관련서비스를 목적으로 하며, 셀러가 상품등록,판매에 대한 상품과 관련해서는 일체의 책임을 지지 않습니다. <br />③{" "}
                        <br />
                        “몰”은 통신판매중개자로서 효율적인 서비스를 위한 시스템 운영 및 관리 책임만을 부담하며, 재화 또는 용역의 거래와 관련하여
                        구매자 또는 판매자를 대리하지 아니하고, 회원 사이에 성립된 거래 및 회원이 제공하고 등록한 정보에 대해서는 해당 회원이 그에
                        대한 직접적인 책임을 부담하여야 합니다. <br /> ④ <br />
                        “몰”은 본사가 제공하는 시스템을 통하여 이루어지는 구매자와 판매자 간의 거래와 관련하여 판매의사 또는 구매의사의 존부 및
                        진정성, 등록물품의 품질, 완전성, 안정성, 적법성 및 타인의 권리에 대한 비침해성, 구매자 또는 판매자가 입력하는 정보 및 그
                        정보를 통하여 자료의 진실성 또는 적법성 등 일체에 대하여 보증하지 아니하며, 이와 관련한 일체의 위험과 책임은 해당 회원이
                        전적으로 부담합니다.
                    </span>
                </p>
                <span>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</span>
                <p>
                    <span className="agreement-subtitle">제5조(서비스의 중단)</span> <br />
                    <span>
                        ① <br />
                        “몰”은 천재지변 또는 이에 준하는 불가항력으로 인하여 서비스를 제공할 수 없는 경우에는 서비스의 제공을 제한하거나 일시 중단할
                        수 있습니다.
                        <br /> ②<br /> “몰”은 컴퓨터 등 정보통신설비의 보수점검, 교체 및 고장, 통신의 두절 등의 사유가 발생한 경우에는 서비스의 제공을
                        일시적으로 중단할 수 있습니다.
                    </span>
                </p>
                <span>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</span>
                <p>
                    <span className="agreement-subtitle">제6조(회원가입)</span> <br />
                    <span>
                        ① <br />
                        이용자는 "몰"이 정한 가입 양식에 따라 회원정보를 기입한 후 이 약관에 동의한다는 의사표시를 함으로서 회원가입을 신청합니다.
                        <br /> ② <br />
                        회원가입은 만 14세 이상의 개인 또는 사업자(개인사업자 및 법인사업자)가 할 수 있으며, 이용신청자는 실명으로 가입신청을 해야
                        하며, 실명이 아니거나 타인의 정보를 도용하는 경우 서비스이용이 제한되거나 관련 법령에 의거 처벌받을 수 있습니다. <br />
                        "몰"은 제1항과 같이 회원으로 가입할 것을 신청한 이용자 중 다음 각호에 해당하지 않는 한 회원으로 등록합니다. <br />
                        1. <br />
                        가입신청자가 이 약관 제7조제3항에 의하여 이전에 회원자격을 상실한 적이 있는 경우, 다만 제7조제3항에 의한 회원자격 상실 후
                        3년이 경과한 자로서 "몰"의 회원 재가입 승낙을 얻은 경우에는 예외로 한다.
                        <br /> 2. <br />
                        등록 내용에 허위, 기재누락, 오기가 있는 경우 <br />
                        3. <br />
                        기타 회원으로 등록하는 것이 "몰"의 기술상 현저히 지장이 있다고 판단되는 경우
                        <br /> ③<br /> 회원가입계약의 성립시기는 "몰"의 승낙이 회원에게 도달한 시점으로 합니다. <br />④<br /> 회원은 제15조제1항에
                        의한 등록사항에 변경이 있는 경우, 즉시 전자우편 기타 방법으로 "몰" 에 대하여 그 변경사항을 알려야 합니다.
                    </span>
                </p>
                <span>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</span>
                <p>
                    <span className="agreement-subtitle">제7조(회원 탈퇴 및 자격 상실 등) </span> <br />
                    <span>
                        ① <br /> 회원은 "몰"에 언제든지 탈퇴를 요청할 수 있으며 "몰"은 즉시 회원 탈퇴를 처리합니다. <br /> ② <br />
                        회원이 다음 각호의 사유에 해당하는 경우, "몰"은 회원자격을 제한 및 정지시킬 수 있습니다. <br /> 1. <br /> 가입 신청 시에
                        허위내용을 등록한 경우 <br />
                        2. <br />
                        "몰"을 이용하여 구입한 재화 등의 대금, 기타 "몰"이용에 관련하여 회원이 부담하는 채무를 기일에 지급하지 않는 경우 <br />
                        3. <br /> 다른 사람의 "몰" 이용을 방해하거나 그 정보를 도용하는 등 전자상거래 질서를 위협하는 경우 <br /> 4. <br /> "몰"을
                        이용하여 법령 또는 이 약관이 금지하거나 공서양속에 반하는 행위를 하는 경우 <br /> ③ <br /> "몰"이 회원 자격을 제한, 정지시킨
                        후, 동일한 행위가 2회 이상 반복되거나 30일 이내에 그 사유가 시정되지 아니하는 경우 "몰"은 회원자격을 상실시킬 수 있습니다.
                        <br />④ <br />
                        "몰"이 회원자격을 상실시키는 경우에는 회원 등록을 말소합니다. 이 경우 회원에게 이를 통지하고, 회원 등록 말소 전에 최소한 30일
                        이상의 기간을 정하여 소명할 기회를 부여합니다. <br />
                    </span>
                </p>
                <span>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</span>
                <p>
                    <span className="agreement-subtitle">제8조(회원에 대한 통지)</span> <br />
                    <span>
                        ① <br />
                        "몰"이 회원에 대한 통지를 하는 경우, 회원이 "몰"과 미리 약정하여 지정한 전자우편 주소로 할 수 있습니다. <br /> ② <br /> "몰"은
                        불특정 다수 회원에 대한 통지의 경우 1주일 이상 "몰" 게시판에 게시함으로써 개별 통지에 갈음할 수 있습니다. 다만, 회원 본인의
                        거래와 관련하여 중대한 영향을 미치는 사항에 대하여는 개별 통지를 합니다.
                    </span>
                </p>
                <span>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</span>
                <p>
                    <span className="agreement-subtitle">제9조(구매신청)</span> <br />
                    <span>
                        몰 이용자는 "몰"상에서 다음 또는 이와 유사한 방법에 의하여 구매를 신청하며, "몰"은 이용자가 구매 신청을 함에 있어서 다음의 각
                        내용을 알기 쉽게 제공하여야 합니다. 단, 회원인 경우 제2호 내지 제4호의 적용을 제외할 수 있습니다.
                        <br /> 1. <br />
                        재화 등의 검색 및 선택 <br />
                        2. <br />
                        성명, 주소, 전화번호, 전자우편 주소(또는 이동전화번호) 등의 입력
                        <br /> 3.
                        <br /> 약관 내용, 청약철회권이 제한되는 서비스, 배송료, 설치비 등의 비용 부담과 관련한 내용에 대한 확인
                        <br /> 4.
                        <br /> 이 약관에 동의하고 위 3. 호의 사항을 확인하거나 거부하는 표시(예, 마우스 클릭)
                        <br /> 5. <br />
                        재화 등의 구매 신청및 이에 관한 확인또는 "몰"의 확인에 대한 동의
                        <br />
                        6.
                        <br /> 결제방법의 선택
                    </span>
                </p>
                <span>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</span>
                <p>
                    <span className="agreement-subtitle">제10조 (계약의 성립)</span> <br />
                    <span>
                        ① <br />
                        "몰"은 제9조와 같은 구매 신청에 대하여 다음 각호에 해당하면 승낙하지 않을 수 있습니다. 다만, 미성년자와 계약을 체결하는
                        경우에는 법정대리인의 동의를 얻지 못하면 미성년자 본인 또는 법정대리인이 계약을 취소할 수 있다는 내용을 고지하여야 합니다.{" "}
                        <br />
                        1. <br />
                        신청 내용에 허위, 기재누락, 오기가 있는 경우 <br />
                        2. <br /> 미성년자가 담배, 주류 등 청소년보호법에서 금지하는 재화 및 용역을 구매하는 경우 <br />
                        3. <br />
                        기타 구매 신청에 승낙하는 것이 "몰" 기술상 현저히 지장이 있다고 판단하는 경우 ② <br /> "몰"의 승낙이 제12조제1항의 수신확인
                        통지형태로 이용자에게 도달한 시점에 계약이 성립한 것으로 봅니다. <br /> ③ "몰"의 승낙의 의사표시에는 이용자의 구매 신청에 대한
                        확인 및판매 가능 여부, 구매 신청의 정정 취소 등에 관한정보 등을 포함하여야 합니다.
                    </span>
                </p>
                <span>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</span>
                <p>
                    <span className="agreement-subtitle">제11조(지급방법)</span> <br />
                    <span>
                        몰에서 구매한 재화 또는 용역에 대한 대금 지급 방법은 다음 각호의 방법 중 가용한 방법으로 할 수 있습니다. <br /> <br />
                        1. <br /> 폰뱅킹, 인터넷뱅킹, 메일 뱅킹 등의 각종 계좌이체 <br /> 2. <br /> 선불카드, 직불카드, 신용카드 등의 각종 카드 결제
                        <br />
                        3. <br />
                        온라인 무통장입금 <br /> 4. <br />
                        전자화폐에 의한 결제 <br />
                        5. <br />
                        수령 시 대금지급 <br /> 6. <br /> 마일리지 등 "몰"이 지급한 포인트에 의한 결제 <br />
                        7. <br />
                        몰"과 계약을 맺었거나 "몰"이 인정한 상품권에 의한 결제 <br /> 8. <br />
                        기타 전자적 지급 방법에 의한 대금 지급등
                    </span>
                </p>
                <span>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</span>
                <p>
                    <span className="agreement-subtitle">제12조(수신확인통지, 구매신청 변경 및 취소)</span> <br />
                    <span>
                        ① <br />
                        "몰"은 이용자의 구매 신청이 있는 경우 이용자에게 수신확인 통지를 합니다. <br /> ② <br />
                        수신확인 통지를 받은 이용자는 의사표시의 불일치 등이 있는 경우에는 수신확인 통지를 받은 후 즉시 구매 신청 변경 및 취소를
                        요청할 수 있고 "몰"은 배송 전에 이용자의 요청이 있는 경우에는 지체 없이 그 요청에 따라 처리하여야 합니다. 다만 이미 대금을
                        지불한 경우에는 제15조의 청약철회 등에 관한 규정에 따릅니다.
                    </span>
                </p>
                <span>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</span>
                <p>
                    <span className="agreement-subtitle">제13조(재화등의 공급)</span> <br />
                    <span>
                        ① <br />
                        "몰"은 이용자와 재화 등의 공급시기에 관하여 별도의 약정이 없는 이상, 이용자가 청약을 한 날부터 3일 이내에 재화 등을 배송할 수
                        있도록 주문 제작, 포장 등 기타의 필요한 조치를 취합니다. 다만, "몰"이 이미 재화 등의 대금의 전부 또는 일부를 받은 경우에는
                        대금의 전부 또 는 일부를 받은 날부터 3영업일 이내에 조치를 취합니다. 이때 "몰"은 이용자가 재화 등의 공급 절차 및 진행사항을
                        확인할 수 있도록 적절한 조치를 합니다.
                        <br /> ②<br /> "몰"은 이용자가 구매한 재화에 대해 배송수단, 수단별 배송비용 부담자, 수단별 배송기간 등을 명시합니다. 판매자의
                        발송확인 처리 이후에 구매자가 수취확인을 하지 않아 배송중 상태가 지연될 경우, 회사는 발송확인일로부터 2주 이내에 수취확인요청
                        안내를 할 수 있습니다. 회사의 안내 이후에도 구매자의 수취확인이 이루어지지 않을 경우 3일이 경과한 시점에 자동으로 배송완료로
                        전환될 수 있으며, 이 경우 실제 구매자가 상품을 수령하지 못한 경우에 구매자는 미수취 신고를 할 수 있습니다.
                    </span>
                </p>
                <span>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</span>
                <p>
                    <span className="agreement-subtitle">제14조(환급)</span> <br />
                    <span>
                        "몰"은 이용자가 구매 신청한 재화 등이 품절 등의 사유로 인도 또는 제공을 할 수 없을 때에는 지체 없이 그 사유를 이용자에게
                        통지하고사전에 재화 등의대금을 받은경우에는 대금을 받은 날부터 7영업일 이내에 환급하거나 환급에 필요한 조치를 취합니다.
                    </span>
                </p>
                <span>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</span>
                <p>
                    <span className="agreement-subtitle">제15조(청약철회 등)</span> <br />
                    <span>
                        ① <br />
                        "몰"과 재화 등의 구매에 관한 계약을 체결한 이용자는 수신확인의 통지를 받은 날부터 7일 이내에는 청약의 철회를 할 수 있습니다.
                        <br /> ② <br />
                        이용자는 재화 등을 배송받은 경우 다음 각호의 1에 해당하는 경우에는 반품 및 교환을 할 수 없습니다. <br /> 1. <br /> 이용자에게
                        책임 있는 사유로 재화 등이 멸실 또는 훼손된 경우(다만, 재화 등의 내용을 확인하기 위하여 포장 등을 훼손한 경우에는 청약철회를
                        할 수 있습니다) <br />
                        2. <br /> 이용자의 사용 또는 일부 소비에 의하여 재화 등의 가치가 현저히 감소한 경우 <br />
                        3. <br />
                        시간의 경과에 의하여 재판매가 곤란할 정도로 재화 등의 가치가 현저히 감소한 경우 <br />
                        4. <br />
                        같은 성능을 지닌 재화 등으로 복제가 가능한 경우 그 원본인 재화 등의포장을 훼손한 경우 <br /> ③ <br /> 이용자는 제1항 및
                        제2항의 규정에 불구하고 재화 등의 내용이 표시·광고 내용과 다르거나 계약 내용과 다르게 이행된 때에는 당해 재화 등을 공급받은
                        날부터 3월 이내, 그 사실을 안 날 또는 알 수 있었던 날부터 30일 이내에 청약철회 등을 할 수 있습니다.
                    </span>
                </p>
                <span>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</span>
                <p>
                    <span className="agreement-subtitle">제 16 조 (구매안전 서비스)</span> <br />
                    <span>
                        ① 회사는 선지급식 통신판매에 있어서 구매자가 지급하는 결제대금을 예치하고 배송이 완료된 후 재화 또는용역의 대금을 판매자에게
                        지급함으로써 구매자의 안전을 도모합니다.
                        <br /> ② 회사는 구매자에게 상품을 공급받은 사실을 재화 등을 공급받은 날(배송완료일)로부터 3영업일 이내에 통보하여 주도록
                        요청합니다.
                        <br />③ 회사는 구매자가 재화 등을 공급받고 [구매결정]항목을 클릭할 경우 그 익일(토요일, 공휴일 제외)에 판매회원에게 결제대금을
                        지급하고, 배송완료일로부터 7일 이내에 [구매결정]을 클릭하지 않는 경우에는 7일이 경과한 날로부터 2영업일 이내에 판매회원에게
                        결제대금을 지급할 수 있습니다. 다만, 회사가 판매회원에게 결제대금을 지급하기 이전에 구매자가 취소, 반품, 교환 또는 환불의
                        의사를 표시한 경우에는 그 지급을 보류합니다.
                    </span>
                </p>
                <span>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</span>
                <p>
                    <span className="agreement-subtitle">제17조(청약철회 등의 효과)</span> <br />
                    <span>
                        ① "몰"은 이용자로부터 재화 등을 반환받은 경우 3영업일 이내에 이미 지급받은 재화 등의 대금 을 환급합니다. ② "몰"은 위 대금을
                        환급함에 있어서 이용자가 신용카드 또는 전자화폐 등의 결제수단으로 재화 등의 대금을 지급한 때에는 지체 없이 당해 결제수단을
                        제공한 사업자로 하여금 재화 등의 대 금의 청구를 정지 또는 취소하도록 요청합니다. ③ 청약철회 등의 경우 공급받은 재화 등의
                        반환에 필요한 비용은 이용자가 부담합니다. "몰"은 이용자에게 청약철회 등을 이유로 위약금 또는 손해배상을 청구하지 않습니다.
                    </span>
                </p>
                <span>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</span>
                <p>
                    <span className="agreement-subtitle">제 16 조 (구매안전 서비스)</span> <br />
                    <span>
                        ① 회사는 선지급식 통신판매에 있어서 구매자가 지급하는 결제대금을 예치하고 배송이 완료된 후 재화 또는용역의 대금을 판매자에게
                        지급함으로써 구매자의 안전을 도모합니다.
                        <br /> ② 회사는 구매자에게 상품을 공급받은 사실을 재화 등을 공급받은 날(배송완료일)로부터 3영업일 이내에 통보하여 주도록
                        요청합니다.
                        <br />③ 회사는 구매자가 재화 등을 공급받고 [구매결정]항목을 클릭할 경우 그 익일(토요일, 공휴일 제외)에 판매회원에게 결제대금을
                        지급하고, 배송완료일로부터 7일 이내에 [구매결정]을 클릭하지 않는 경우에는 7일이 경과한 날로부터 2영업일 이내에 판매회원에게
                        결제대금을 지급할 수 있습니다. 다만, 회사가 판매회원에게 결제대금을 지급하기 이전에 구매자가 취소, 반품, 교환 또는 환불의
                        의사를 표시한 경우에는 그 지급을 보류합니다.
                    </span>
                </p>
                <span>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</span>
                <p>
                    <span className="agreement-subtitle">제17조(청약철회 등의 효과)</span> <br />
                    <span>
                        ①<br /> "몰"은 이용자로부터 재화 등을 반환받은 경우 3영업일 이내에 이미 지급받은 재화 등의 대금을 환급합니다. <br /> ② <br />
                        "몰"은 위 대금을 환급함에 있어서 이용자가 신용카드 또는 전자화폐 등의 결제수단으로 재화 등의 대금을 지급한 때에는 지체 없이
                        당해 결제수단을 제공한 사업자로 하여금 재화 등의 대금의 청구를 정지 또는 취소하도록 요청합니다.
                        <br />③<br /> 청약철회 등의 경우 공급받은 재화 등의 반환에 필요한 비용은 이용자가 부담합니다. "몰"은 이용자에게 청약철회 등을
                        이유로 위약금 또는 손해배상을 청구하지 않습니다.
                    </span>
                </p>
                <span>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</span>
                <p>
                    <span className="agreement-subtitle">제18조(개인정보보호)</span> <br />
                    <span>
                        ① <br />
                        "몰"은 이용자의 정보 수집 시 구매계약 이행에 필요한 최소한의 정보를 수집합니다.
                        <br /> 다음 사항을 필수사항으로 하며 그 외 사항은 선택사항으로 합니다. <br />
                        1.
                        <br /> 성명
                        <br /> 2. <br />
                        주민등록번호(회원의 경우) 또는 외국인등록번호
                        <br /> 3. <br />
                        주소
                        <br /> 4.
                        <br /> 전화번호 <br />
                        5. <br />
                        희망 ID(회원의 경우)
                        <br /> 6. <br />
                        비밀번호(회원의 경우)
                        <br /> 7.
                        <br /> 전자우편 주소(또는 이동전화번호)
                        <br /> ②<br /> "몰"이 이용자의 개인식별이 가능한 개인 정보를 수집하는 때에는 반드시 당해 이용자의 동의를 받습니다.
                        <br />③ <br />
                        제공된 개인 정보는 당해 이용자의 동의 없이 목적 외의 이용이나 제3자에게 제공할 수 없으며, 이에 대한 모든 책임은 "몰"이 집니다.
                        다만, 다음의 경우에는 예외로 합니다. <br />
                        1. <br />
                        배송업무상 배송업체에게 배송에 필요한 최소한의 이용자의 정보(성명, 주소, 전화번호)를 알려주는 경우
                        <br /> 2. <br />
                        통계 작성, 학술연구 또는 시장조사를 위하여 필요한 경우로서 특정 개인을 식별할 수 없는 형태로 제공하는 경우
                        <br /> 3. <br />
                        재화 등의 거래에 따른 대금 정산을 위하여 필요한 경우 <br />
                        4. <br />
                        도용방지를 위하여 본인확인에 필요한 경우
                        <br /> 5. <br />
                        법률의 규정 또는 법률에 의하여 필요한 불가피한 사유가 있는 경우
                        <br /> ④<br />
                        "몰"이 제2항과 제3항에 의해 이용자의 동의를 받아야 하는 경우에는 개인정보관리 책임자의 신원(소속, 성명 및 전화번호, 기타
                        연락처), 정보의 수집 목적 및 이용목적, 제3자에 대한 정보제공 관련 사항(제공받은 자, 제공 목적 및 제공할 정보의 내용) 등
                        정보통신망 이용 촉진 등에 관한 법률 제22조제2항이 규정한 사항을 미리 명시하거나 고지해야 하며 이용자는 언제든지 이 동의를
                        철회할 수 있습니다.
                        <br /> ⑤ <br />
                        이용자는 언제든지 "몰"이 가지고 있는 자신의 개인 정보에 대해 열람 및 오류 정정을 요구할 수 있으며 "몰"은 이에 대해 지체 없이
                        필요한 조치를 취할 의무를 집니다. 이용자가 오류의 정정을 요구한 경우에는 "몰"은 그 오류를 정정할 때까지 당해 개인 정보를
                        이용하지 않습니다.
                        <br /> ⑥<br /> "몰"은 개인 정보 보호를 위하여 관리자를 한정하여 그 수를 최소화하며 신용카드, 은행 계좌 등을 포함한 이용자의
                        개인 정보의 분실, 도난, 유출, 변조 등으로 인한 이용자의 손해에 대하여 모든 책임을 집니다. <br />⑦<br /> "몰" 또는 그로부터
                        개인 정보를 제공받은 제3자는 개인 정보의 수집 목적 또는 제공 받은 목적을 달성한 때에는 당해 개인 정보를 지체없이 파기합니다.
                    </span>
                </p>
                <span>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</span>
                <p>
                    <span className="agreement-subtitle">제19조("몰"의 의무)</span> <br />
                    <span>
                        ①<br /> "몰"은 법령과 이 약관이 금지하거나 공서양속에 반하는 행위를 하지 않으며 이 약관이 정하는 바에 따라 지속적이고,
                        안정적으로 재화․용역을 제공하는데 최선을 다하여야 합니다. <br />② <br />
                        "몰"은 이용자가 안전하게 인터넷 서비스를 이용할 수 있도록 이용자의 개인정보(신용정보 포함)보호를 위한 보안 시스템을 갖추어야
                        합니다.
                        <br /> ③<br /> "몰"이 상품이나 용역에 대하여 「표시&#xb7;광고의 공정화에 관한 법률」 제3조 소정의 부당한 표시&#xb7;광고행위를
                        함으로써 이용자가 손해를 입은 때에는 이를 배상할 책임을 집니다.
                        <br /> ④<br /> "몰"은 이용자가 원하지 않는 영리목적의 광고성 전자우편을 발송하지 않습니다.
                    </span>
                </p>
                <span>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</span>
                <p>
                    <span className="agreement-subtitle">제20조(회원의 ID 및 비밀번호에 대한 의무)</span> <br />
                    <span>
                        ① <br />
                        제17조의 경우를 제외한 ID와 비밀번호에 관한 관리 책임은 회원에게 있습니다.
                        <br /> ② <br />
                        회원은 자신의 ID 및 비밀번호를 제3자에게 이용하게 해서는 안 됩니다.
                        <br /> ③ <br />
                        회원이 자신의 ID 및 비밀번호를 도난당하거나 제3자가 사용하고 있음을 인지한 경우에는 바로 "몰"에 통보하고 "몰"의 안내가 있는
                        경우에는 그에 따라야 합니다.
                    </span>
                </p>
                <span>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</span>
                <p>
                    <span className="agreement-subtitle">제21조(이용자의 의무)</span> <br />
                    <span>
                        이용자는 다음 행위를 하여서는 안됩니다. <br />
                        1. <br />
                        신청 또는 변경시 허위내용의 등록
                        <br /> 2. <br />
                        '몰'에 게시된 정보의 변경 <br />
                        3. <br />
                        '몰'이 정한 정보 이외의 정보(컴퓨터 프로그램 등)의 송신 또는 게시 <br />
                        4. <br />
                        기타 제3자의 저작권 등 지적재산권에 대한 침해 <br />
                        5. <br />
                        기타 제3자의 명예를 손상시키거나 업무를 방해하는 행위
                    </span>
                </p>
                <span>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</span>
                <p>
                    <span className="agreement-subtitle">제 22 조 (회사의 면책)</span> <br />
                    <span>
                        ① “몰”을 통한 거래시스템을 이용한 거래 내용에 관한 모든 분쟁에 대해서는 당해 거래 당사자가 책임을 져야 합니다.
                        <br /> ② 제5조에 의한 사유로 인하여 서비스를 일시적으로 중단하는 경우 회사는 이로 인하여 회원 또는 제3자가 입은 손해에 대하여
                        책임지지 않습니다. 단, 회사의 고의 또는 중과실로 인한 경우에는 그러하지 아니합니다.
                        <br /> ③ 회사는 회원의 귀책사유로 인한 서비스 이용의 장애에 대하여 책임을 지지 않습니다.
                        <br /> ④ 회원이 자신의 개인정보를 타인에게 유출 또는 제공함으로써, 발생하는 피해에 대해서 회사는 일체의 책임을 지지 않습니다.
                        <br /> ⑤ “몰”의 정보 제공은 실시간으로 진행되지 않을 수도 있습니다. 데이터 서비스 제공자의 네트워크 등의 이유로 제한 및 지연될
                        수 있습니다.
                    </span>
                </p>
                <span>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</span>
                <p>
                    <span className="agreement-subtitle">제23조(연결"몰"과 피연결"몰" 간의 관계)</span> <br />
                    <span>
                        ①<br /> 상위 "몰"과 하위 "몰"이 하이퍼 링크(예: 하이퍼 링크의 대상에는 문자, 그림 및 동화상 등이 포함됨)방식 등으로 연결된
                        경우, 전자를 연결 "몰"(웹 사이트)이라고 하고 후자를 피연결 "몰"(웹사이트)이라고 합니다. <br /> ② <br /> 연결"몰"은
                        피연결"몰"이 독자적으로 제공하는 재화 등에 의하여 이용자와 행하는 거래에 대해서 보증책임을 지지 않는다는 뜻을 연결"몰"의
                        초기화면 또는 연결되는 시점의 팝업 화면으로 명시한 경우에는 그 거래에 대한 보증책임을 지지 않습니다.
                    </span>
                </p>
                <span>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</span>
                <p>
                    <span className="agreement-subtitle">제24조(저작권의 귀속 및 이용제한)</span> <br />
                    <span>
                        ① <br /> "몰"이 작성한 저작물에 대한 저작권 기타 지적재산권은 "몰"에 귀속합니다.
                        <br /> ②<br /> 이용자는 "몰"을 이용함으로써 얻은 정보 중 "몰"에게 지적재산권이 귀속된 정보를 "몰"의 사전 승낙 없이 복제, 송신,
                        출판, 배포, 방송 기타 방법에 의하여 영리목적으로 이용하거나 제3자에게 이용하게 하여서는 안됩니다. <br /> ③ <br /> "몰"은
                        약정에 따라 이용자에게 귀속된 저작권을 사용하는 경우 당해 이용자에게 통보하여야 합니다.
                    </span>
                </p>
                <span>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</span>
                <p>
                    <span className="agreement-subtitle">제25조(분쟁해결)</span> <br />
                    <span>
                        ① <br /> "몰"은 이용자가 제기하는 정당한 의견이나 불만을 반영하고 그 피해를 확인하고 고충처리를 적극적으로 협조합니다.
                        <br /> ②<br /> "몰"은 이용자로부터 제출되는 불만사항 및 의견은 우선적으로 그 사항을 처리합니다. 다만, 신속한 처리가 곤란한
                        경우에는 이용자에게 그 사유와 처리 일정을 즉시 통보 해 드립니다. <br /> ③<br /> "몰"과 이용자 간에 발생한 전자상거래 분쟁과
                        관련하여 이용자의 피해 구제신청이 있는 경우에는 공정거래위원회 또는 시·도지사가 의뢰하는 분쟁 조정 기관의 조정에 따를 수
                        있습니다.
                    </span>
                </p>
                <span>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</span>
                <p>
                    <span className="agreement-subtitle">제26조(재판권 및 준거법)</span> <br />
                    <span>
                        ① <br />
                        "몰"과 이용자 간에 발생한 전자상거래 분쟁에 관한 소송은 제소 당시의 이용자의 주소에 의하고, 주소가 없는 경우에는 거소를
                        관할하는 지방법원의 전속관할로 합니다. 다만, 제소 당시 이용자의 주소 또는 거소가 분명하지 않거나 외국 거주자의 경우에는
                        민사소송법상의 관할법 원에 제기합니다. <br />② <br />
                        "몰"과 이용자 간에 제기된 전자상거래 소송에는 한국법을 적용합니다.
                    </span>
                </p>
                <span>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</span>
                <p>
                    <span className="agreement-subtitle">제26조(판매권의 정의)</span> <br />
                    <span>
                        판매자 및 회원 외 정당한거래가 아닌 재판매 및 불법거래등 문제발생 시 주식회사 액츠에서 회원 및 판매자에게 손해배상을 청구할 수
                        있습니다.
                        <br />
                        <br /> (시행일) <br />이 약관은 2024년 7월 30일부터 시행합니다. <br />
                        <br /> 개인정보 수집 및 이용 (필수) <br />
                        [개인정보의 수집ㆍ이용 목적]
                        <br /> “몰”이 개인정보 수집ㆍ이용 목적은 아래와 같습니다.
                        <br />
                        <br /> 서비스 제공에 관한 계약이행: 요금정산, 배송, 고객상담 및 민원처리 <br />
                        주문관리: 본인확인, 개인식별, 불량 이용자의 부정 이용방지, 서비스 이용연령 제한, 분쟁조정을 위한 기록보존, 불만처리, 고지사항
                        전달 <br />
                        마케팅 활용: 서비스 이용 통계, 접속빈도 파악
                        <br /> 법령의 준수: 서비스와 관련하여 법령에 따른 기록의 보존 및 제공 <br />
                        마케팅 정보 제공 : 맞춤형 소식지, 신상품 홍보, 이벤트 등 각종혜택 정보와 SMS 및 메일수신 제공 <br />
                        [수집하는 개인정보 항목]
                        <br /> “몰”이 개인정보를 수집하는 항목은 아래와 같습니다. <br /> <br />
                        물품 주문 시: 주문자의 성명, 전화번호, 이메일, 주문비밀번호와 물품 수령인의 성명, 배송주소, 전화번호, 기타요구사항 <br />
                        대금 결제 시: 은행계좌정보 또는 신용카드정보 <br />
                        현금영수증 발급 시: 휴대전화번호
                        <br /> 주문정보 확인 시: IP주소, 방문일시 <br />
                        [개인정보의 보유 및 이용 기간]
                        <br /> “몰”은 거래완결 시까지 및 관계법령에 따라 회사가 개인정보 및 기록보유 의무나 권한을 가진 기간 동안 개인정보를 보유하고
                        이용하게 됩니다. <br />
                        <br />
                        계약 또는 청약철회 등에 관한 기록 : 5년 이상 <br />
                        대금결제 및 재화등의 공급에 관한 기록 : 5년 이상 <br />
                        소비자의 불만 또는 분쟁처리에 관한 기록 : 3년 이상
                        <br />
                        {"<선택 동의항목>"}
                    </span>
                </p>
            </div>
        </div>
    );
};

export default Terms;
