import { HeaderTitle } from "../../components/Text";
import CenterSrc from "../../assets/images/last-center-img.png";
import RightDrc from "../../assets/images/last-right-img.png";
import LeftSrc from "../../assets/images/last-left-img.png";
import LeftBg from "../../assets/images/last-bg.png";
import MobileBg from "../../assets/images/last-mobile-bg.png";
import "./lastpage.css";
import { Mobile, PC } from "../../hooks/useMedia";
import { IcTriangle, IcTriangleMobile } from "../../constants/icons";

const LastPage = () => {
    return (
        <div className={"last-page"}>
            <div className={"last-page-blur"} />
            <PC>
                <div className="last-page-deco">
                    <IcTriangle />
                </div>
                <img className={"last-page-bg"} src={LeftBg} />
                <HeaderTitle>
                    당신의 <span>고품격 라이프 스타일</span>
                    {"을 완성시켜 줄 \n"}Shopping App
                </HeaderTitle>
            </PC>
            <Mobile>
                <div className="last-page-deco">
                    <IcTriangleMobile />
                </div>
                <img className={"last-page-bg"} src={MobileBg} />
                <HeaderTitle>
                    당신의 <span>{"고품격 라이프\n 스타일"}</span>
                    {"을 완성시켜 줄 \n"}Shopping App
                </HeaderTitle>
            </Mobile>
            <div className={"last-page-imgs"}>
                <img className={"last-page-img-left"} src={LeftSrc} />
                <img className={"last-page-img-right"} src={RightDrc} />
                <img className={"last-page-img-center"} src={CenterSrc} />
            </div>
        </div>
    );
};

export default LastPage;
