import { useEffect } from "react";
import { useNavigate, useParams, Navigate } from "react-router-dom";

import Header from '../pages/Layout/Header';
import ShopDetail from '../pages/ShopDetail/ShopDetail';
import Footer from '../pages/Layout/Footer';

const ShopMore = () => {

    return (
       <>
        <Header />
        <ShopDetail />
        <Footer/>
        </>
    );
};

export default ShopMore;
