import "../agreement.css";

const Privacy = () => {
    return (
        <div className="privacy-wrapper">
            <div title="Page 1">
                <span>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;</span>
                <div>
                    <span>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;</span>
                    <div>
                        <span>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;</span>
                        <p className="agreement-title">온라인(웹 & 앱) 서비스 개인정보보호방침</p>
                        <span>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; </span>
                        <p>
                            <span>
                                주식회사 액츠(이하 "회사"라 함)는 정보 통신망 이용 촉진 및 정보 보호 등에 관한 법률, 개인정보 보호법, 통신 비밀
                                보호법, 전기 통신 사업법, 등 정보 통신 서비스 제공자가 준수하여야 할 관련 법령상의 개인정보 보호 규정을 준수하며, 관련
                                법령에 의거한 개인정보 취급 방침을 정하여 이용자 권익 보호에 최선을 다하고 있습니다. <br /> 회사의 개인정보 취급
                                방침은 정부의 법률 및 지침의 변경과 당사의 약관 및 내부 정책에 따라 변경될 수 있으며, 이를 개정하게 될 경우 변경
                                사항에 대해서 적용일자 10일 전부터 적용일 이후 상당 기간 동안 공지만 합니다. 회원에게 불리한 약관의 개정의 경우에는 그
                                적용일자 30일 전부터 적용일 이후 상당한 기간 동안 각각 이를 서비스 홈페이지에 게시하거나 회원에게 ‘전자우편’, ‘전자
                                쪽지’, 로그인시 ‘동의 창’ 등의 전자적 수단을 통해 따로 명확히 통지하도록 합니다.
                            </span>
                        </p>

                        <span>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</span>
                        <p>
                            <span>
                                <span className="agreement-subtitle">1. 수집하는 개인정보의 항목 및 수집방법</span>
                                <br />
                                가. 수집하는 개인정보의 항목
                                <br />
                                <span>
                                    첫째, 회사는 회원 구분, 원활한 서비스의 이용을 위해 최초 회원가입 당시 아래와 같은 최소한의 개인정보를 필수
                                    항목으로 수집하고 있습니다.
                                </span>
                                <br />
                                - 기기의 아이디, 비밀번호, 휴대폰 번호, 이메일 주소
                                <br />
                                <span>둘째, 서비스 이용과정이나 사업의 처리 과정에서 아래와 같은 정보들이 자동으로 생성되어 수집될 수 있습니다.</span>
                                <br /> - IP Address, 서비스 이용 기록, 불량 이용 기록
                                <br />
                                <span>
                                    셋째, 유료 서비스 이용 과정에서 아래와 같은 결제 정보들이 수집될 수 있습니다. <br />
                                </span>
                                - 휴대전화 결제 시 : 이동전화번호, 통신사, 결제 승인 번호, 은행계좌번호 등
                            </span>
                            <span>
                                <br />
                                나. 개인정보 수집 방법
                                <br />
                                <span>회사는 다음과 같은 방법으로 개인정보를 수집합니다.</span>
                                <br />- 앱 접속 시 자동으로 수집
                            </span>
                        </p>

                        <span>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</span>
                        <p>
                            <span>
                                <span className="agreement-subtitle">2. 개인정보의 수집 및 이용 목적</span>
                                <br />
                                가. 서비스 제공에 관한 계약 이행 및 서비스 제공에 따른 요금정산
                                <br />
                                <span>컨텐츠 제공, 특정 맞춤 서비스 제공, 물품 배송 또는 청구서 등 발송, 본인 인증, 구매 및 요금 결제</span>
                                <br />
                                나. 회원 관리
                                <br />
                                <span>
                                    회원제 서비스 제공, 개인 식별, 불량 회원의 부정 이용 방지와 비인가 사용 방지, 가입 의사 확인, 가입 및 가입 횟수
                                    제한, 불만 처리 등 민원 처리, 고지사항 전달, 회원 탈퇴 의사의 확인
                                </span>
                                <br />
                                다. 신규 서비스 개발 및 마케팅·광고에의 활용
                                <br />
                                <span>
                                    신규 서비스 개발 및 맞춤 서비스 제공, 통계학적 특성에 따른 서비스 제공 및 광고 게재, 서비스의 유효성 확인, 이벤트
                                    및 광고성 정보 제공 및 참여 기회 제공, 접속 빈도 파악, 회원의 서비스 이용에 대한 통계, 쿠키에 의한 개인정보 수집
                                </span>
                            </span>
                        </p>
                        <span>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</span>
                        <p>
                            <span>
                                ① 쿠키(cookie)란? <br />
                                몰은 귀하에 대한 정보를 저장하고 수시로 찾아내는 ‘쿠키(cookie)'를 사용합니다. 쿠키는 웹사이트 또는 앱은 귀하의 컴퓨터
                                및 휴대폰브라우저(크롬, 사파리, 엣지, 웨일, 넷스케이프, 인터넷 익스플로러 등)로 전송하는 소량의 정보입니다. 귀하께서
                                웹사이트 및 앱에 접속을 하면 몰의 컴퓨터는 귀하의 기기에 있는 쿠키의 내용을 읽고, 귀하의 추가정보를 귀하의 기기에서
                                찾아 접속에 따른 추가 입력 없이 서비스를 제공할 수 있습니다. 쿠키는 귀하의 기기는 식별하지만 귀하를 개인적으로
                                식별하지는 않습니다.
                            </span>
                        </p>
                        <span>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;</span>
                        <p>
                            <span>
                                ② 쿠키(cookie) 운용
                                <br /> 몰은 이용자의 편의를 위하여 쿠키를 운영합니다. 몰이 쿠키를 통해 수집하는 정보는 회원 ID에 한하며, 그 외의 다른
                                정보는 수집하지 않습니다. 쿠키(cookie)를 통해 수집한 회원 ID는 다음의 목적을 위해 사용됩니다. <br /> - 쇼핑한 품목들에
                                대한 정보와 관심있게 둘러본 품목들에 대한 자취를 추적하여 다음번 쇼핑 때 개인 맞춤 서비스를 제공 <br /> - 게시판 글
                                등록 <br /> 쿠키는 브라우저의 종료시나 로그아웃시 만료됩니다.
                            </span>
                        </p>
                    </div>
                    <span>&nbsp; &nbsp; &nbsp; &nbsp;</span>
                    <div>
                        <p>
                            <span>
                                <span className="agreement-subtitle">3. 개인정보의 공유 및 제공</span>
                                <br />
                                회사는 이용자들의 개인정보를 "2. 개인정보의 수집목적 및 이용목적"에서 고지한 범위 내에서 사용하며, 이용자의 사전 동의
                                없이는 동 범위를 초과하여 이용하거나 원칙적으로 이용자의 개인정보를 외부에 공개하지 않습니다. 다만, 아래의 경우에는
                                예외로 합니다. <br /> 가. 이용자가 사전에 동의 한 경우 <br /> 나. 법령의 규정에 의거하거나, 수사 목적으로 법령에
                                정해진 절차와 방법에 따라 수사기관의 요구가 있는 경우
                            </span>
                        </p>
                    </div>
                    <span>&nbsp; &nbsp; &nbsp; &nbsp;</span>
                    <div>
                        <p>
                            <span>
                                <span className="agreement-subtitle">4. 개인정보의 취급 공유 및 위탁</span>
                                <br />
                                회사는 개인정보를 위탁하거나 공유할 수 있습니다. <br />- 제휴관계 : 보다 나은 서비스 제공을 위하여 귀하의 개인정보를
                                제휴사에게 제공하거나 또는 제휴사와 공유할 수 있습니다. 개인정보를 제공하거나 공유할 경우에는 사전에 귀하께 제휴사가
                                누구인지, 제공 또는 공유되는 개인정보항목이 무엇인지, 왜 그러한 개인정보가 제공되거나 공유되어야 하는지, 그리고
                                언제까지 어떻게 보호, 관리되는지에 대해 개별적으로 전자우편 및 서면을 통해 고지하여 동의를 구하는 절차를 거치게 되며,
                                귀하께서 동의하지 않는 경우에는 제휴사에게 제공하거나 제휴사와 공유하지 않습니다. 제휴관계에 변화가 있거나 제휴관계가
                                종결될 때도 같은 절차에 의하여 고지하거나 동의를 구합니다.
                            </span>
                        </p>
                        <span>&nbsp; &nbsp; &nbsp; &nbsp;</span>
                        <p>
                            <span>
                                - 위탁 처리 : 원활한 업무 처리를 위해 이용자의 개인정보를 위탁 처리할 경우 반드시 사전에 위탁처리 업체명과 위탁
                                처리되는 개인정보의 범위, 업무위탁 목적, 위탁 처리되는 과정, 위탁관계 유지기간 등에 대해 상세하게 고지합니다.
                            </span>
                        </p>
                        <span>&nbsp; &nbsp; &nbsp; &nbsp;</span>
                        <p>
                            <span>
                                - 매각/인수합병 등 : 서비스제공자의 권리와 의무가 완전 승계, 이전되는 경우 반드시 사전에 정당한 사유와 절차에 대해
                                상세하게 고지할 것이며 이용자의 개인정보에 대한 동의 철회의 선택권을 부여합니다. <br />
                                위탁기관 <br />
                                본인인증 <br />
                                결제대행 <br /> 제휴사
                            </span>
                        </p>
                    </div>
                    <div>
                        <p>
                            <span>
                                <span className="agreement-subtitle">5. 개인정보의 보유 및 이용기간</span>
                                <br />
                                회원의 개인정보는 원칙적으로 개인정보의 수집 및 이용목적이 달성되면 지체 없이 파기합니다. <br />
                                단, 다음의 정보에 대해서는 아래의 이유로 명시한 기간 동안 보존합니다. <br />
                                가. 회사 내부 방침에 의한 정보 보유 사유 <br />- 부정 이용 기록 <br /> <span>&nbsp; &nbsp;</span>• 보존 이유 : 부정
                                이용 방지 <br /> <span>&nbsp; &nbsp;</span>• 보존 기간 : 5년 <br /> 나. 관련 법령에 의한 정보 보유 사유 상법, <br />
                                전자상거래 등에서의 소비자 보호에 관한 법률 등 관계 법령의 규정에 의하여 보존 할 필요가 있는 경우 회사는 관계 법령에서
                                정한 일정한 기간 동안 회원 정보를 보관합니다.
                                <br />이 경우 회사는 보관하는 정보를 그 보관의 목적으로만 이용하며 보존 기간은 아래와 같습니다. <br /> - 대금결제 및
                                재화 등의 공급에 관한 기록 <br /> <span>&nbsp; &nbsp;</span>• 보존 이유 : 전자상거래 등에서의 소비자보호에 관한 법률
                                <br /> <span>&nbsp; &nbsp;</span>• 보존 기간 : 5년
                                <br /> - 전자 금융 거래에 관한 기록 <br /> <span>&nbsp; &nbsp;</span>• 보존 이유 : 전자 금융 거래법 <br />
                                <span>&nbsp; &nbsp;</span> • 보존 기간 : 5년 <br /> - 소비자의 불만 또는 분쟁 처리에 관한 기록 <br />
                                <span>&nbsp; &nbsp;</span>• 보존 이유 : 전자상거래 등에서의 소비자 보호에 관한 법률 <br /> <span>&nbsp; &nbsp;</span>
                                • 보존 기간 : 3년 <br /> - 웹사이트 및 앱 방문기록 <br /> <span>&nbsp; &nbsp;</span>• 보존 이유 : 통신 비밀 보호법{" "}
                                <br /> <span>&nbsp; &nbsp;</span>• 보존 기간 : 3개월
                            </span>
                        </p>
                    </div>
                    <div>
                        <span>&nbsp; &nbsp; &nbsp; &nbsp;</span>
                        <p>
                            <span>
                                <span className="agreement-subtitle">6. 개인정보 파기절차 및 방법</span>
                                <br />
                                이용자의 개인정보는 원칙적으로 개인정보의 수집 및 이용목적이 달성되면 지체 없이 파기합니다. 회사의 개인정보 파기절차
                                및 방법은 다음과 같습니다. <br />
                                가. 파기절차
                                <br /> - 이용자가 회원가입 등을 위해 입력한 정보는 목적이 달성된 후 별도의 DB로 옮겨져(종이의 경우 별도의 서류함)
                                내부방침 및 기타 관련 법령에 의한 정보 보호 사유에 따라(보유 및 이용기간 참조) 일정 기간 저장된 후 파기됩니다.
                                <br /> - 동 개인정보는 법률에 의한 경우가 아니고서는 보유되는 이외의 다른 목적으로 이용되지 않습니다.
                                <br /> 나. 파기 방법
                                <br /> - 종이에 출력된 개인정보는 분쇄기로 분쇄하거나 소각을 통하여 파기합니다.
                                <br /> - 전자적 파일 형태로 저장된 개인정보는 기록을 재생할 수 없는 기술적 방법을 사용하여 삭제합니다.
                            </span>
                        </p>
                    </div>
                    <span>&nbsp; &nbsp; &nbsp; &nbsp;</span>
                    <div>
                        <p>
                            <span>
                                <span className="agreement-subtitle">7. 이용자의 권리와 그 행사 방법</span>
                                <br />
                                가. 이용자는 언제든지 등록되어 있는 자신의 개인정보를 조회하거나 수정할 수 있으며, 회사의 개인정보의 처리에 동의하지
                                않는 경우 동의를 거부하거나 가입해지(회원탈퇴)를 요청하실 수 있습니다. 다만, 그러한 경우 서비스의 일부 또는 전부
                                이용이 어려울 수 있습니다. <br />
                                나. 이용자는 개인정보 조회, 수정을 위해서는 '개인정보 변경'(또는 '회원 정보 수정' 등)을, 가입 해지(동의 철회)를
                                위해서는 "회원 탈퇴"를 클릭하여 본인 확인 절차를 거치신 후 직접 열람, 정정 또는 탈퇴가 가능합니다. <br /> 다. 혹은
                                개인정보 관리 책임자에게 서면, 전화 또는 이메일로 연락하시면 지체 없이 조치합니다. <br /> 라. 이용자가 개인정보의
                                오류에 대한 정정을 요청하신 경우에는 정정을 완료하기 전까지 당해 개인정보를 이용 또는 제공하지 않습니다. 또한 잘못된
                                개인정보를 제3자에게 이미 제공한 경우에는 정정 처리 결과를 제3자에게 지체 없이 통지하여 정정이 이루어지도록
                                하겠습니다. <br /> 마. 회사는 이용자의 요청에 의해 해지 또는 삭제된 개인정보는 "5. 개인정보의 보유 및 이용 기간"에
                                명시된 바에 따라 처리하고 그 외의 용도로 열람 또는 이용할 수 없도록 처리하고 있습니다.
                            </span>
                        </p>
                    </div>
                    <span>&nbsp; &nbsp; &nbsp; &nbsp;</span>
                    <div>
                        <p>
                            <span>
                                <span className="agreement-subtitle">8. 개인정보 자동 수집 장치의 설치/운영 및 거부에 관한 사항</span>
                                <br />
                                회사에서는 수집한 회원의 개인정보는 기기의 아이디, 휴대폰번호, 이메일이며, 수집을 거부하는 경우에는 앱을 즉시
                                삭제하시면 됩니다.
                            </span>
                        </p>
                    </div>
                    <span>&nbsp; &nbsp; &nbsp; &nbsp;</span>
                    <div>
                        <p>
                            <span>
                                <span className="agreement-subtitle">9. 개인정보의 기술적/관리적 보호 대책</span>
                                <br />
                                회사는 이용자들의 개인정보를 취급함에 있어 개인정보가 분실, 도난, 누출, 변조 또는 훼손되지 않도록 안전성 확보를 위하여
                                다음과 같은 기술적/관리적 대책을 강구하고 있습니다. <br />
                                가. 수집정보 암호화 <br />
                                29몰은 개인정보의 취급 및 앱 특성상 최소한의 개인정보만을 수집하고 있습니다. <br /> 나. 해킹 등에 대비한 대책 <br />
                                회사는 해킹이나 컴퓨터 바이러스 등에 의해 회원의 개인정보가 유출되거나 훼손되는 것을 막기 위해 최선을 다하고 있습니다.
                                개인정보의 훼손에 대비해서 자료를 수시로 백업하고 있고, 최신 백신 프로그램을 이용하여 이용자들의 개인정보나 자료가
                                누출되거나 손상되지 않도록 방지하고 있으며, 암호화 통신 등을 통하여 네트워크상에서 개인정보를 안전하게 전송할 수
                                있도록 하고 있습니다. 그리고 침입 차단 시스템을 이용하여 외부로부터의 무단 접근을 통제하고 있으며, 기타 시스템적으로
                                보안성을 확보하기 위한 가능한 모든 기술적 장치를 갖추기 위해 노력하고 있습니다. <br /> 다. 취급 직원의 최소화 및 교육
                                <br />
                                회사의 개인정보 관련 취급 직원은 담당자에 한정시키고 있고 이를 위한 담당자에 대한 수시 교육을 통하여 개인정보 취급
                                방침의 준수를 항상 강조하고 있습니다. <br /> 라. 이용자 본인의 부주의 <br /> 이용자 본인의 부주의나 인터넷상의 문제로
                                ID, 비밀번호, 이메일 등 개인정보가 유출되어 발생한 문제에 대해 회사는 일체의 책임을 지지 않습니다. <br /> 마.
                                링크사이트 <br /> 29몰은 귀하께 다른 회사의 웹사이트 또는 자료에 대한 링크를 제공할 수 있습니다. 이 경우 외부사이트 및
                                자료에 대한 아무런 통제권이 없으므로 그로부터 제공받는 서비스나 자료 의 유용성에 대해 책임질 수 없으며 보증할 수
                                없습니다. 29몰이 포함하고 있는 링크를 클릭(click)하여 타 사이트(site)의 페이지로 옮겨갈 경우 해당 사이트의
                                개인정보처리방침은 29몰과 무관하므로 새로 방문한 사이트의 정책을 검토하셔야 합니다.
                            </span>
                        </p>
                    </div>
                </div>
                <span>&nbsp; &nbsp; &nbsp; &nbsp;</span>
                <div>
                    <p>
                        <span>
                            <span className="agreement-subtitle">10. 개인정보 관리 책임자 및 담당자의 연락처</span>
                            <br />
                            귀하께서는 회사의 서비스를 이용하시며 발생하는 모든 개인정보 보호 관련 민원을 개인정보 관리 책임자 혹은 담당 부서로
                            신고하실 수 있습니다. 회사는 이용자들의 신고사항에 대해 신속하게 충분한 답변을 드릴 것입니다. <br />
                            <br />■ 개인정보 관리 책임자 <br />성 명 : 김 대 용 <br /> 전 화 : 1800-8952 <br />직 위 : 팀 장 <br /> 메 일 :
                            acts29mall@naver.com
                            <br />
                            <br /> 기타 개인정보 침해에 대한 신고나 상담이 필요하신 경우에는 아래 기관에 문의하시기 바랍니다. <br /> • 개인정보 침해
                            신고 센터 (www.118.or.kr / 국번없이 118) <br />• 대검찰청 사이버 범죄 수사단 (www.spo.go.kr / 02-3480-2000) <br />• 경찰청
                            사이버 테러 대응센터 (www.ctrc.go.kr / 1566-0112)
                        </span>
                    </p>
                </div>
                <span>&nbsp; &nbsp; &nbsp; &nbsp;</span>
                <div>
                    <p>
                        <span>
                            <span className="agreement-subtitle">11. 고지의 의무</span> <br />현 개인정보 취급 방침 내용 추가, 삭제 및 수정이 있을
                            시에는 개정 최소 7일 전부터 해당 앱의 '공지사항'을 통해 고지할 것입니다. 다만, 개인정보의 수집 및 활용, 제3자 제공 등과
                            같이 이용자 권리의 중요한 변경이 있을 경우에는 최소 30일 전에 고지합니다. <br /> • 공고 일자 : 2024년 07월 30일 <br /> •
                            시행 일자 : 2024년 08월 30일
                        </span>
                    </p>
                </div>
            </div>
            <div title="Page 2">
                <span>
                    <br />
                </span>
            </div>
            <p>
                <span>&nbsp; &nbsp; </span>
            </p>
            <div title="Page 3">
                <br />
            </div>
        </div>
    );
};

export default Privacy;
