import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom/client";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import DaumPostcode from "react-daum-postcode";
import { toast } from "react-toastify";
import "./shopdetail.css";
import { yupResolver } from "@hookform/resolvers/yup";
import PaymentWallet from "./../../assets/images/store/payment_card.svg";
import bgSrc from "../../assets/images/store/store_banner_bg.png";
import FormInput from "../../components/Input/FormInput";
import { useLocation, useParams } from "react-router-dom";
import { currency } from "../../utils/common";
import { updatePurchaseGoods, updatePurchaseGoodsHash } from "../../utils/apiList";

const DEFAULT_VALUES = {
    name: "",
    address1: "",
    address2: "",
    postcode: "",
    phone1: "",
    phone2: "",
    phone3: "",
    email1: "",
    email2: "",
};

const validationSchema = yup.object({
    name: yup.string().required(),
    address1: yup.string().required(),
    postcode: yup.string().required(),
    phone1: yup.number().required(),
    phone2: yup.number().required(),
    phone3: yup.number().required(),
    email1: yup.string().required(),
    email2: yup.string().required(),
});
const ShopDetail = () => {
    const [openAddress, setOpenAddress] = useState(false);
    const [showError, setShowError] = useState(false);
    const [itemData, setItemData] = useState({
        title: "",
        description: "",
        price: "",
        image: null,
    });
    const location = useLocation();
    const params = useParams();
    const { id } = params;

    const root = ReactDOM.createRoot(document.getElementById("root"));

    const appHeight = () => {
        const doc = document.documentElement;
        doc.style.setProperty("--app-height", `${window.innerHeight}px`);
    };
    window.addEventListener("resize", appHeight);
    appHeight();

    const {
        handleSubmit,
        watch,
        control,
        trigger,
        formState: { errors, isDirty, dirtyFields },
        setValue,
        reset,
    } = useForm({
        defaultValues: {
            ...DEFAULT_VALUES,
        },
        resolver: yupResolver(validationSchema),
    });

    const completeHandler = (data) => {
        const { address, zonecode } = data;
        setValue("postcode", zonecode);
        setValue("address1", address);
        trigger();
    };
    const onSubmit = async (data) => {
        const body = {
            timestamp: Math.floor(Date.now() / 1000),
            product_id: id,
            fullname: data?.name,
            address: data?.address1 + " " + data?.address2,
            phone: data?.phone1 + data.phone2 + data?.phone3,
            email: data?.email1 + "@" + data?.email2,
        };
        const res = await updatePurchaseGoods(body);
        if (res?.result === "Y") {
            let form = document.createElement("form");
            form.id = "paypayform";
            form.name = "paypayform";
            form.method = "post";
            document.body.appendChild(form);

            for (let key in res?.pg_params) {
                if (res?.pg_params.hasOwnProperty(key)) {
                    let input = document.createElement("input");
                    input.type = "hidden";
                    input.name = key;
                    input.value = res?.pg_params[key];
                    document.paypayform.appendChild(input);
                }
            }
            document.paypayform.action = res?.pg_req_url;

            // let iLeft = window.screen.width / 2 - Number(815) / 2;
            // let iTop = window.screen.height / 2 - Number(650) / 2;
            // let features =
            //     "menubar=no,toolbar=no,status=no,resizable=no,scrollbars=no,location=no,left=" +
            //     iLeft +
            //     ",top=" +
            //     iTop +
            //     ",width=" +
            //     815 +
            //     ",height=" +
            //     650;
            // let popupWindow = window.open("", "_self", features);
            // popupWindow.focus();
            let target = "_self";
            document.paypayform.target = target;
            document.paypayform.submit();
            document.paypayform.remove();
        }
    };

    useEffect(() => {
        if (location?.state?.data) {
            setItemData(location?.state?.data);
        }
    }, [location]);
    return (
        <div className="payment-container">
            <form name="form_payment" method="post"></form>
            <form className="payment-inner" onSubmit={handleSubmit(onSubmit)}>
                <div className="payment-top">
                    <p className="title">주문/결제</p>
                </div>
                <div className="goods-wrap">
                    <p className="goods-title">주문 상품</p>
                    <div className="goods-box">
                        <div className="goods-img-wrap">
                            <div className="goods-img" style={{ backgroundImage: `url(${itemData?.image})` }} />
                        </div>
                        <div className="goods-content-wrap">
                            <div className="goods-top-content">
                                <p className="goods-top-title">{itemData?.title}</p>
                                <p className="goods-top-sub">{itemData?.description}</p>
                            </div>
                            <p className="goods-top-sub">{currency(itemData?.price)}원</p>
                        </div>
                    </div>
                </div>
                <div className="user-wrap">
                    <p className="goods-title">구매자 정보</p>
                    <div className="user-input-container">
                        <div>
                            <div className="user-input-wrap">
                                <label>
                                    받는사람 <span className="input-required">*</span>
                                </label>
                                <FormInput control={control} name={"name"} className="user-name-input" />
                            </div>
                            {showError && errors?.name && <span className="input-error-text">받는사람을 입력해주세요.</span>}
                        </div>
                        <div>
                            <div className="user-input-wrap">
                                <label>
                                    주소 <span className="input-required">*</span>
                                </label>
                                <div className="input-address-wrap">
                                    <div className="user-postcode-input-wrap">
                                        <FormInput control={control} name={"postcode"} disabled />
                                        <div className="user-post-btn" onClick={() => setOpenAddress(true)}>
                                            주소검색
                                        </div>
                                    </div>
                                    <FormInput control={control} name={"address1"} className="user-address-input" disabled placeholder="" />
                                    <FormInput control={control} name={"address2"} className="user-address-input" placeholder="상세 주소 (선택)" />
                                </div>
                            </div>
                            {showError && errors?.address1 && <span className="input-error-text">주소를 입력해주세요.</span>}
                        </div>
                        <div>
                            <div className="user-input-wrap">
                                <label>
                                    휴대전화 <span className="input-required">*</span>
                                </label>
                                <div className="user-number-wrap">
                                    <FormInput maxLength={4} control={control} name={"phone1"} className="user-number-input" />
                                    <FormInput maxLength={4} control={control} name={"phone2"} className="user-number-input" />
                                    <FormInput maxLength={4} control={control} name={"phone3"} className="user-number-input" />
                                </div>
                            </div>
                            {showError && (errors?.phone1 || errors?.phone2 || errors?.phone3) && (
                                <span className="input-error-text">번호를 입력해주세요.(숫자만 입력 가능합니다)</span>
                            )}
                        </div>
                        <div>
                            <div className="user-input-wrap">
                                <label>
                                    이메일 <span className="input-required">*</span>
                                </label>
                                <div className="user-email-wrap">
                                    <FormInput control={control} name={"email1"} className="user-email-input" />
                                    <p className="user-email-icon">@</p>
                                    <FormInput control={control} name={"email2"} className="user-email-input" />
                                </div>
                            </div>
                            {showError && (errors?.email1 || errors?.email2) && <span className="input-error-text">이메일을 입력해주세요.</span>}
                        </div>
                    </div>
                </div>
                <div className="payment-wrap">
                    <p className="goods-title">결제 정보</p>
                    <div className="payment-content-container">
                        <div className="payment-content-wrap">
                            <p className="payment-title">상품 금액</p>
                            <p className="payment-price">{currency(itemData?.price)}원</p>
                        </div>
                        <div className="payment-content-wrap">
                            <p className="payment-title">배송비</p>
                            <p className="payment-price text-blue">배송비 무료</p>
                        </div>
                        <div className="payment-content-wrap">
                            <p className="payment-title payment-bold">총 결제 금액</p>
                            <p className="payment-price payment-bold">{currency(itemData?.price)}원</p>
                        </div>
                    </div>
                </div>
                <div className="payment-method-wrap">
                    <p className="goods-title">결제수단</p>
                    <button className="payment-card-btn">
                        <img src={PaymentWallet} />
                        <p>카드 결제</p>
                    </button>
                </div>
                <div className="btn-wrap">
                    <button
                        type="submit"
                        onClick={async () => {
                            const isValid = await trigger();
                            setShowError(true);
                            !isValid && toast.warn("데이터를 확인해주세요.");
                        }}
                        className="payment-btn"
                    >
                        {currency(itemData?.price)}원 결제하기
                    </button>
                </div>
            </form>
            {openAddress && (
                <div className="postcode-modal-wrap">
                    <button onClick={() => setOpenAddress(false)} className="close-btn">
                        <p>close</p>
                    </button>
                    <div className="postcode-modal-inner">
                        <DaumPostcode onComplete={completeHandler} onClose={() => setOpenAddress(false)} />
                    </div>
                </div>
            )}
        </div>
    );
};

export default ShopDetail;
