import "./fourthpage.css";
import imgSrc from "../../assets/images/fourth-img.png";
import { Square } from "../../components/Square";
import { IcFourthLogo } from "../../constants/icons";
import { Mobile, PC } from "../../hooks/useMedia";

const FourthPage = () => {
    return (
        <div className={"fourth-page"}>
            <div className="fourth-left">
                <PC>
                    <div className="fourth-left-wrapper margin">
                        <div className="fourth-left-text-wrapper">
                            <div className="fourth-left-text">
                                {"일상을 더\n"} <span>특별</span> {"하고\n"} <span>간편</span> 하게
                            </div>
                        </div>
                        <Square num={0} />
                        <Square num={1} />
                    </div>
                    <div className="fourth-left-wrapper ">
                        <Square num={2} />
                        <IcFourthLogo className="fourth-logo" />
                        <Square num={3} />
                    </div>
                </PC>
                <Mobile>
                    <div className="fourth-left-wrapper margin">
                        <div className="fourth-left-text-wrapper">
                            <div className="fourth-left-text">
                                {"일상을 더\n"} <span>특별</span> {"하고\n"} <span>간편</span> 하게
                            </div>
                        </div>
                        <Square num={0} />
                    </div>
                    <div className="fourth-left-wrapper margin">
                        <Square num={1} />
                        <IcFourthLogo className="fourth-logo" />
                    </div>
                    <div className="fourth-left-wrapper ">
                        <Square num={2} />
                        <Square num={3} />
                    </div>
                </Mobile>
            </div>
            <PC>
                <div className="fourth-right">
                    <img src={imgSrc} />
                </div>
            </PC>
        </div>
    );
};

export default FourthPage;
