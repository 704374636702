import { useEffect, useState } from "react";
import { IcHeaderCollapse, IcHeaderLogo } from "../../constants/icons";
import { Mobile, PC } from "../../hooks/useMedia";
import "./layout.css";
import { commingSoon, downloadApk, downloadXlsx } from "../../utils/common";
import appGuide from "../../assets/videos/29mall_Install_Guide.mp4";
import { useLocation, useNavigate } from 'react-router-dom';

const OS_TYPE = [
    {
        type: "apk",
        label: "Apk 설치하기",
    },
    {
        type: "guide",
        label: "Apk 이용 가이드",
    },
    // {
    //     type:"android",
    //     label:"Android",
    // },
    // {
    //     type:"ios",
    //     label:"iOS",
    // },
];

const HeaderShop = () => {
    const [isOpen, setIsOpen] = useState(true);
    const navigate = useNavigate()

    const onClicekButton = (type) => {
        if (type === "apk") {
            downloadApk();
            return;
        }
        if (type === "guide") {
            window.open(appGuide);
            return;
        }
        if (type === "seller") {
            downloadXlsx();
            return;
        }
        commingSoon();
    };

    const gotoDownload = () => {
        navigate('/', {state : 'download'})
        
        window.scrollTo({
            top: 6400,
            left: 0,
            behavior: "smooth",
        });
    };

    return (
        <>
            <div className="header">
                <IcHeaderLogo className="header-logo" />
                <Mobile>
                    <IcHeaderCollapse
                        className="header-collapse-btn"
                        onClick={() => {
                            setIsOpen((prev) => !prev);
                        }}
                    />
                    {isOpen ? (
                        <div className="header-collapse-body">
                            <div className="download">
                                입점문의 : acts29mall@naver.com
                                <span className="seller_email" onClick={() => onClicekButton("seller")}>
                                    셀러 입점 양식 다운로드
                                </span>
                            </div>
                            {/* <div className="download">29Mall 다운로드</div>
                            {OS_TYPE.map((item, index) => (
                                <div className="os" key={index} onClick={() => onClicekButton(item.type)}>
                                    {item.label}
                                </div>
                            ))} */}
                        </div>
                    ) : (
                        ""
                    )}
                </Mobile>
                <PC>
                    <div className="header-btn-wrapper">
                        <p className={`header-btn shop-active`} onClick={navigate('/store')}>
                        29Mall Shopping
                        </p>
                        <p
                            className={`header-btn active shop`}
                            onClick={() => {
                                navigate('/')
                            }}
                        >
                            About
                        </p>
                        <p className={`header-btn shop`} onClick={gotoDownload}>
                            Download
                        </p>
                        <div className={`header-btn btn-down`} onClick={() => onClicekButton("seller")}>
                            <p className={`btn-down-text `}> 입점하기 </p>
                        </div>
                    </div>
                </PC>
            </div>
        </>
    );
};

export default HeaderShop;
