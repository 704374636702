import { useEffect } from "react";
import { useNavigate, useParams, Navigate } from "react-router-dom";

import Header from '../pages/Layout/Header';
import ShopList from '../pages/ShopList/ShopList';
import HeaderShop from '../pages/Layout/HeaderShop';
import Footer from '../pages/Layout/Footer';

const Shop = () => {

    return (
       <>
        <HeaderShop />
        <ShopList />
        <Footer/>
        </>
    );
};

export default Shop;
