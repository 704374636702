import "./fifthpage.css";
import imgSrc from "../../assets/images/fifth-img.png";
import { IcFifithLeft, IcFifithRight, IcFifthMobileLeft, IcFifthMobileRight, IcFourthLogo } from "../../constants/icons";
import { Mobile, PC } from "../../hooks/useMedia";

const FifthPage = () => {
    return (
        <div className={"fifth-page"}>
            <PC>
                <IcFifithLeft className={"fifth-bg-left"} />
                <IcFifithRight className={"fifth-bg-right"} />
            </PC>
            <Mobile>
                <IcFifthMobileLeft className={"fifth-bg-left"} />
                <IcFifthMobileRight className={"fifth-bg-right"} />
            </Mobile>
            <div className="fifth-page-body">
                <div className="fifth-title">
                    29Mall과 <Mobile>{"\n"}</Mobile>함께 할 <span>셀러 모집</span>
                </div>
                <div className="fifth-imgs">
                    <div className="fifth-speech first">“사용자가 많은 스토어를 찾으시나요?”</div>
                    <div className="fifth-speech second">“판매하는 상품의 수익률이 낮아서 걱정이신가요?”</div>
                    <img src={imgSrc} />
                </div>
                <div className="fifth-bottom">
                    <div className="fifth-bottom-title">{"29Mall에서는 걱정하실 필요 없습니다.\n 아래 이메일로 문의하세요."}</div>
                    <div className="fifth-bottom-email">acts29mall@naver.com</div>
                </div>
            </div>
        </div>
    );
};

export default FifthPage;
